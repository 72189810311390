<script>
import { FormWizard, TabContent } from "@anivive/vue3-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  data() {
    return {
      title: "Yönetici Detay",
      items: [
        {
          text: "Kullanıcılar",
          href: "/",
        },
        {
          text: "Yönetici Detay",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card-body">
                        <form-wizard color="#556ee6">
                            <tab-content icon="mdi mdi-account-circle">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="name"
                                                >{{$t('user.FirstName')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="name"
                                                class="form-control"
                                                value="Francis"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="surname"
                                                >{{$t('user.FirstName')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="surname"
                                                class="form-control"
                                                value="Brinkman"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="name"
                                                >{{$t('user.Designation')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="text"
                                                name="name"
                                                class="form-control"
                                                value="Frontend Developer"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="email"
                                                >{{$t('user.Email')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                type="email"
                                                name="email"
                                                class="form-control"
                                                value="cory1979@hotmail.com"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="password"
                                                >{{$t('user.Password')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                id="password"
                                                type="password"
                                                name="password"
                                                class="form-control"
                                                value="123456789"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row mb-3">
                                            <label class="col-md-3 col-form-label" for="confirmpass"
                                                >{{$t('user.RePassword')}}</label
                                            >
                                            <div class="col-md-9">
                                                <input
                                                id="confirmpass"
                                                type="password"
                                                name="confirmpass"
                                                class="form-control"
                                                value="123456789"
                                                />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <button type="submit" class="btn btn-primary me-1">
                                            {{$t('user.SaveChanges')}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                        </form-wizard>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>            
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>



